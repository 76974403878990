import Pages from "./pages/Pages";
import Category from "./components/Category";
import { Link, BrowserRouter } from 'react-router-dom'
import Search from "./components/Search";
import styled from 'styled-components'
import { GiKnifeFork } from 'react-icons/gi'
function App() {
  return (
    <BrowserRouter className="App">
      <Nav>
        <Container>
          <GiKnifeFork />
          <Logo to={'/'}>MenuKitty</Logo>
        </Container>
      </Nav>
      <Search />
      <Category />
      <Pages />
    </BrowserRouter>
  );
}
const Container = styled.div`
width: 960px;
margin: 0 auto;
`
const Logo = styled(Link)`
  text-decoration:none;
  font-size:2.5rem;
  font-weight:400;
  font-family: 'Rancho', sans-serif;
  margin-left: 1.5rem;
`
const Nav = styled.div`
padding: 2rem 0;
display: flex;
justify-content: flex-start;
align-items: center;
background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
background-size: 400% 400%;
animation: gradient 15s ease infinite;
box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
a {
  color: white;
}
svg {
  font-size:1.75rem;
  color: white;
}`
export default App;