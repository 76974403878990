import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
function Recipe() {
  let params = useParams()
  const [details, setDetails] = useState("")
  const [activeTab, setActiveTab] = useState("instructions")
  useEffect(() => {
    const fetchDetails = async () => {
      const data = await fetch(`https://api.spoonacular.com/recipes/${params.name}/information?apiKey=${process.env.REACT_APP_API_KEY}`)
      const detailData = await data.json()
      setDetails(detailData)
    }
    fetchDetails()
  }, [params.name])
  return (
    <Container>
      <DetailWrapper>
        <div>
          <img src={details.image} alt="" width={300} />
        </div>
        <Info>
          <h2>{details.title}</h2>
          <Button className={activeTab === 'instructions' ? 'active' : ''} onClick={() => { setActiveTab('instructions') }}>Instructions</Button>
          <Button className={activeTab === 'ingredients' ? 'active' : ''} onClick={() => { setActiveTab('ingredients') }}>Ingredients</Button>
          {activeTab === 'instructions' && (
            <div>
              <p dangerouslySetInnerHTML={{ __html: details.summary }}></p>
              <p dangerouslySetInnerHTML={{ __html: details.instructions }}></p>
            </div>
          )}
          {activeTab === 'ingredients' && (
            <ul>
              {details.extendedIngredients.map((ingredient) => (
                <li key={ingredient.id}>{ingredient.original}</li>
              ))}
            </ul>
          )}
        </Info>
      </DetailWrapper>
    </Container>
  )
}
const Container = styled.div`
width: 960px;
margin: 0 auto;
`
const DetailWrapper = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 5rem;
  display: flex;
  .active{
    background: linear-gradient(35deg, rgb(73, 73, 73), #313131);
    color: white
  }
  ul, ol {
    margin: 1.5rem;
  }
`
const Button = styled.button`
  padding: 1rem 2rem;
  color: #313131;
  background: white;
  border: 1px solid black;
  margin: 2rem 2rem 2rem 0;
  font-weight: 600;
  cursor: pointer;
`
const Info = styled.div`
  margin-left: 2rem;
`
export default Recipe