import styled from 'styled-components'
import { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
function Search() {
  const [input, setInput] = useState("")
  const navigate = useNavigate()
  const submitHandler = (e) => {
    e.preventDefault()
    navigate('/searched/' + input)
  }
  return (
    <Wrapper>
      <Container>
        <FormStyle onSubmit={submitHandler}>
          <div>
            <FaSearch />
            <input onChange={(e) => setInput(e.target.value)} type="text" value={input} />
          </div>
        </FormStyle>
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
width: 100%;
background: #efefef;
padding: 2rem 0;
box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
`
const Container = styled.div`
width: 960px;
margin: 0 auto;
`
const FormStyle = styled.form`
  div {
    width: 100%;
    position: relative;
  }
  input{
    border: none;
    background: linear-gradient(35deg, #494949, #313131);
    font-size: 1.5rem;
    color: white;
    padding: 1rem 3rem;
    border: none;
    border-radius: 1rem;
    outline: none;
    width: 100%;
  }
  svg{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(100%, -50%);
    color: white;
  }
`
export default Search